import rectangle from "../../assets/rectangle.svg";
import rectangleActive from "../../assets/rectangle-active.svg";

type Props = {
  onClick: (number: number) => void;
  currentNumber: number;
};
const ServeList: React.FC<Props> = ({ onClick, currentNumber }) => {
  const isActive = (current: number) => {
    return current === currentNumber;
  };

  return (
    <div className="w-full">
      <div
        onClick={() => onClick(1)}
        className={`${
          isActive(1) ? "text-white" : "text-[#FFFFFFB2]"
        } font-bold text-[16px] xl:text-[28px] h-[80px] flex items-center w-full mb-4 relative cursor-pointer lg:hover:text-white`}
      >
        <img
          className="w-full absolute left-0 top-0 h-full"
          src={isActive(1) ? rectangleActive : rectangle}
          alt="serve"
        />
        <div className="w-full absolute left-0 top-0 flex items-center h-full pl-8">
          Application Development
        </div>
      </div>
      <div
        onClick={() => onClick(2)}
        className={`${
          isActive(2) ? "text-white" : "text-[#FFFFFFB2]"
        } font-bold text-[16px] xl:text-[28px] h-[80px] flex items-center w-full mb-4 relative cursor-pointer lg:hover:text-white`}
      >
        <img
          className="w-full absolute left-0 top-0 h-full"
          src={isActive(2) ? rectangleActive : rectangle}
          alt="serve"
        />
        <div className="w-full absolute left-0 flex items-center top-0 h-full pl-8">
          Consulting
        </div>
      </div>
      <div
        onClick={() => onClick(3)}
        className={`${
          isActive(3) ? "text-white" : "text-[#FFFFFFB2]"
        } font-bold text-[16px] xl:text-[28px] h-[80px] flex items-center w-full mb-4 relative cursor-pointer lg:hover:text-white`}
      >
        <img
          className="w-full absolute left-0 top-0 h-full"
          src={isActive(3) ? rectangleActive : rectangle}
          alt="serve"
        />
        <div className="w-full absolute left-0 flex items-center top-0 h-full pl-8">
          System Deployment & Management
        </div>
      </div>
      <div
        onClick={() => onClick(4)}
        className={`${
          isActive(4) ? "text-white" : "text-[#FFFFFFB2]"
        } font-bold text-[16px] xl:text-[28px] h-[80px] flex items-center w-full mb-4 relative cursor-pointer lg:hover:text-white`}
      >
        <img
          className="w-full absolute left-0 top-0 h-full"
          src={isActive(4) ? rectangleActive : rectangle}
          alt="serve"
        />
        <div className="w-full absolute left-0 flex items-center top-0 h-full pl-8">
          Monitoring & Evaluating Projects
        </div>
      </div>
      <div
        onClick={() => onClick(5)}
        className={`${
          isActive(5) ? "text-white" : "text-[#FFFFFFB2]"
        } font-bold text-[16px] xl:text-[28px] h-[80px] flex items-center w-full mb-4 relative cursor-pointer lg:hover:text-white`}
      >
        <img
          className="w-full absolute left-0 top-0 h-full"
          src={isActive(5) ? rectangleActive : rectangle}
          alt="serve"
        />
        <div className="w-full absolute left-0 flex items-center top-0 h-full pl-8">
          Custom Solutions Development
        </div>
      </div>
    </div>
  );
};
export default ServeList;
