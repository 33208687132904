import { Col } from "antd";
import quote from "../../assets/quote.svg";

type Props = {
  data: {
    name: string;
    avatar: string;
    title: string;
    content: string;
  };
};
const PeopleSayItem: React.FC<Props> = ({ data }) => {
  return (
    <Col xs={24} md={12}>
      <div className="rounded-[20px] p-6 peopleSayItem relative h-[264px]">
        <div className="flex items-center">
          <div className="h-[56px] w-[56px] mr-4 rounded-full flex justify-center items-center">
            <img src={data.avatar} alt={data.name} className="rounded-full"/>
          </div>
          <div className="flex-1 pr-10">
            <p className="text-elipse-1 text-[20px] leading-[25px] text-white font-bold">
              {data.name}
            </p>
            <p className="text-elipse-1 text-[12px] leading-[18px] text-white"></p>
          </div>
        </div>
        <p className="text-white leading-[24px] text-elipse-6 mt-4">
          {data.content}
        </p>
        <img className="absolute right-6 top-6" src={quote} alt="quote" />
      </div>
    </Col>
  );
};
export default PeopleSayItem;
