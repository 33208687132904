export const AddressIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9922 9.97988C20.9953 8.79916 20.7659 7.62939 20.317 6.53734C19.868 5.4453 19.2084 4.45237 18.3757 3.61525C16.6941 1.92461 14.4097 0.971241 12.0252 0.964876C9.64061 0.958511 7.35119 1.89967 5.66054 3.58131C3.9699 5.26295 3.01654 7.54731 3.01017 9.93188C2.98905 12.7021 3.79323 15.4159 5.32021 17.7274C6.84718 20.0389 9.02781 21.8433 11.5842 22.9109C11.7148 22.97 11.8567 23.0004 12.0002 22.9999C12.1447 23.0001 12.2875 22.9694 12.4192 22.9099C14.9677 21.8456 17.143 20.0486 18.6692 17.7468C20.1954 15.4449 21.0039 12.7417 20.9922 9.97988ZM12.0002 20.8999C9.89211 19.9718 8.10447 18.4427 6.86084 16.504C5.61721 14.5653 4.97279 12.3029 5.00817 9.99988C5.00817 9.08167 5.18902 8.17246 5.54041 7.32415C5.89179 6.47584 6.40681 5.70505 7.05608 5.05579C7.70535 4.40652 8.47614 3.89149 9.32445 3.54011C10.1728 3.18873 11.082 3.00788 12.0002 3.00788C12.9184 3.00788 13.8276 3.18873 14.6759 3.54011C15.5242 3.89149 16.295 4.40652 16.9443 5.05579C17.5935 5.70505 18.1086 6.47584 18.4599 7.32415C18.8113 8.17246 18.9922 9.08167 18.9922 9.99988C18.9922 10.0209 18.9922 10.0449 18.9922 10.0649C19.0164 12.3573 18.3667 14.6063 17.1236 16.5326C15.8805 18.4589 14.099 19.9775 12.0002 20.8999ZM14.0002 9.99988C14.0002 10.3954 13.8829 10.7821 13.6631 11.111C13.4433 11.4399 13.131 11.6963 12.7655 11.8476C12.4001 11.999 11.998 12.0386 11.61 11.9614C11.222 11.8843 10.8657 11.6938 10.586 11.4141C10.3063 11.1344 10.1158 10.778 10.0386 10.3901C9.96143 10.0021 10.001 9.59996 10.1524 9.23451C10.3038 8.86906 10.5601 8.5567 10.889 8.33694C11.2179 8.11717 11.6046 7.99988 12.0002 7.99988C12.5306 7.99988 13.0393 8.21059 13.4144 8.58566C13.7895 8.96074 14.0002 9.46944 14.0002 9.99988Z"
        fill="white"
      />
    </svg>
  );
};
