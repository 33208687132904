type Props = {
  onClick: (nunber: number) => void;
  activeNumber: number;
  amount: number;
};
const DotList: React.FC<Props> = ({ onClick, activeNumber, amount }) => {
  const array = Array.from(Array(amount).keys());
  return (
    <div className="flex items-center justify-center mt-6 ">
      {array.map((item) => (
        <div className="px-1" key={item}>
          <div
            className={`w-[12px] h-[12px] rounded-full ${
              activeNumber === item ? "bg-primary" : "bg-[#cccccc]"
            }`}
            onClick={() => onClick(item + 1)}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default DotList;
