import AboutUs from "../../organisms/AboutUs";
import Footer from "../../organisms/Footer";
import GetInTouch from "../../organisms/GetInTouch";
import Header from "../../organisms/Header";
import Intro from "../../organisms/Intro";
import OurPartner from "../../organisms/OurPartner";
import OurTeam from "../../organisms/OurTeam";
import PeopleSayList from "../../organisms/PeopleSay";
import Projects from "../../organisms/Projects";
import Serves from "../../organisms/Serves";

const Home = () => {
  return (
    <div className="min-h-[100vh]">
      <Header />
      <Intro />
      <AboutUs />
      <Serves />
      <Projects />
      <PeopleSayList />
      <OurTeam />
      <OurPartner />
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default Home;
