import { Row } from "antd";
import client1 from "../../assets/client1.png";
import client2 from "../../assets/client2.jpg";
import client3 from "../../assets/client3.jpg";
import client4 from "../../assets/client4.jpg";
import PeopleSayItem from "../molecules/PeopleSayItem";
import Slider from "react-slick";
import quote from "../../assets/quote.svg";

const peopleSaysData = [
  {
    name: "Mr Hung Vu - CEO & Founder Mata ",
    avatar: client2,
    title: "",
    content:
      "I highly satisfied with the blockchain application development service provided by the company. They have provided an excellent solution for managing their digital assets and enhancing transparency in their business operations. The company clearly understood their requirements and provided a great product that met all their needs. Moreover, their customer service is excellent, always ready to support them during the deployment and use of their product. I  highly values the quality of the company's products and services and will continue to collaborate with them to develop other custom blockchain solutions in the future.",
  },
  {
    name: "Mr Truong - CEO & Founder VGaming & Ecotrade",
    avatar: client3,
    title: "",
    content:
      "The best thing about the company's service is the quality of their products and customer service. They provided the best solutions, helping them save time and labor. Their product is also very user-friendly with a friendly user interface. I  has received many compliments from their clients about the convenience and reliability of their service. I  highly appreciate the service provided by the company and will recommend it to other businesses.",
  },
  {
    name: "Mr Vinh - CEO & Founder ClubOne",
    avatar: client4,
    title: "",
    content:
      "Before deciding to choose this company, I had searched for many blockchain service providers. However, I found that the company's products and services were the best among the suppliers they had researched. They work professionally and always meet their requirements in the shortest time possible. I have collaborated with this company on many different projects and has always been satisfied with their products and services. I trust that the company will continue to develop and deliver better blockchain solutions for other businesses in the future.",
  },
];
const PeopleSayList = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="peopleSayList" id="testimonials">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          What Clients Say?
        </p>
        <Row gutter={[48, 48]} className="mt-10 md:mt-[80px] hidden md:flex">
          {peopleSaysData.map((say, index) => (
            <PeopleSayItem data={say} key={index} />
          ))}
        </Row>
        <div className="mt-10 md:mt-[80px] md:hidden">
          <Slider {...settings} className="people_say_slider">
            {peopleSaysData.map((say, index) => (
              <div className="px-2" key={index}>
                <div className="rounded-[20px] p-6 peopleSayItem relative h-[264px]">
                  <div className="flex items-center">
                    <div className="h-[56px] w-[56px] mr-4 rounded-full flex justify-center items-center">
                      <img src={say.avatar} alt={say.name}/>
                    </div>
                    <div className="flex-1 pr-10">
                      <p className="text-elipse-1 text-[20px] leading-[25px] text-white font-bold">
                        {say.name}
                      </p>
                      <p className="text-elipse-1 text-[12px] leading-[18px] text-white"></p>
                    </div>
                  </div>
                  <p className="text-white leading-[24px] text-elipse-6 mt-4">
                    {say.content}
                  </p>
                  <img
                    className="absolute right-6 top-6"
                    src={quote}
                    alt="quote"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PeopleSayList;
