import { useLocation, useNavigate } from "react-router";

export const navList = [
  {
    path: "#home",
    name: "Home",
  },
  {
    path: "#about_us",
    name: "About us",
  },
  {
    path: "#services",
    name: "Services",
  },
  {
    path: "#projects",
    name: "Projects",
  },
  {
    path: "#testimonials",
    name: "Testimonials",
  },
  {
    path: "#our_teams",
    name: "Our teams",
  },
  {
    path: "#partners",
    name: "Partners",
  },
  {
    path: "#contact_us",
    name: "Contact us",
  },
];
const Navbar = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const isActive = (path: string) => {
    return hash === path || (!hash && path === "#home");
  };
  const handleClickScroll = (path: string) => {
    const element = document.getElementById(path.substring(1));
    if (element) {
      navigate(path);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <nav className="flex items-center h-[40px]">
      {navList.map((item) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            handleClickScroll(item.path);
          }}
          key={item.name}
          className={`w-[100px] cursor-pointer h-full flex items-center justify-center text-[14px] font-bold ${
            isActive(item.path)
              ? "text-primary hover:text-primary border-b-[3px] boder-primary"
              : "text-white hover:text-primary hover:border-b-[3px] hover:boder-primary"
          }`}
        >
          {item.name}
        </div>
      ))}
    </nav>
  );
};
export default Navbar;
