import { Form, Input } from "antd";
import getintouch1 from "../../assets/getintouch1.svg";
import getintouch2 from "../../assets/getintouch2.svg";
import getintouchTitle from "../../assets/getintouchTitle.svg";
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const GetInTouch = () => {
  const [form] = Form.useForm();
  const onFinish = (form: any) => {
    console.log("form", form);
  };
  return (
    <div className="get_in_tounch relative overflow-hidden" id="get_in_tounch">
      <div className="max-w-[720px] w-full mx-auto px-6 py-[100px]">
        <img src={getintouchTitle} alt="Get In Touch" className="mx-auto" />
        <p className="text-[32px] text-white font-bold text-center mt-10 md:mt-[80px]">
          Drop us a message
        </p>
        <div className="mt-10 relative z-[6]">
          <Form
            form={form}
            layout="vertical"
            className="text-white"
            onFinish={onFinish}
          >
            <Form.Item
              name={"email"}
              label="Email"
              rules={[
                {
                  message: "Invalid email!",
                  pattern: new RegExp(emailRegex),
                },
              ]}
            >
              <Input
                className="focus:border-primary text-[16px] hover:border-primary bg-[#FFFFFF14] text-white rounded-[8px] border-input border border-[#FFFFFF80] h-[44px] backdrop-blur-[13px]"
                placeholder="Enter your email here"
              />
            </Form.Item>
            <Form.Item name={"subject"} label="Subject">
              <Input
                className="focus:border-primary text-[16px] hover:border-primary bg-[#FFFFFF14] text-white rounded-[8px] border-input border border-[#FFFFFF80] h-[44px] backdrop-blur-[13px]"
                placeholder="Enter your subject here"
              />
            </Form.Item>
            <Form.Item name={"message"} label="Message">
              <Input.TextArea
                className="focus:border-primary text-[16px] hover:border-primary bg-[#FFFFFF14] text-white rounded-[8px] border-input border border-[#FFFFFF80] backdrop-blur-[13px]"
                placeholder="Enter your message here"
                rows={10}
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="text-[16px] cursor-pointer hover:text-primary mr-0 font-bold text-primary w-[120px] h-[44px] flex justify-center items-center border-[2px] border-primary bg-transparent outline-none mt-[16px]"
                >
                  Send
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <img
        src={getintouch1}
        className="absolute right-[-235px] top-[-100px]"
        alt="ttk"
      />
      <img
        src={getintouch2}
        className="absolute left-[-270px] bottom-[-100px]"
        alt="ttk"
      />
    </div>
  );
};

export default GetInTouch;
