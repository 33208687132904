import application from "../../assets/application.svg";
import consulting from "../../assets/consulting.svg";
import system from "../../assets/system.svg";
import monitoring from "../../assets/monitoring.svg";
import solotion from "../../assets/solotion.svg";
import { Row, Col } from "antd";
import ServeList from "../molecules/ServeList";
import { useState } from "react";
import ServeItem from "../molecules/ServeItem";
import DotList from "../molecules/DotList";
import Slider from "react-slick";

const servesData = [
  {
    icon: application,
    title: "Blockchain Application Development",
    description:
      "Developing blockchain applications such as payment systems, asset management systems, and smart contracts for other types of business.",
  },
  {
    icon: consulting,
    title: "Blockchain Consulting",
    description:
      "Providing consulting services to help business understand the technology and its application in their operations. The service may include evaluating the feasibility of using blockchain, designing system architecture, and training (operation) staff.",
  },
  {
    icon: system,
    title: "Blockchain System Deployment & Management",
    description:
      "Providing deployment and management services for businesses that need them. This may include system installation and configuration, maintenance, and technical support.",
  },
  {
    icon: monitoring,
    title: "Monitoring & Evaluating Blockchain Projects",
    description:
      "Providing monitoring and evaluation services for blockchain projects to ensure that the system operates correctly and meets the business requirements of customers.",
  },
  {
    icon: solotion,
    title: "Custom Blockchain Solutions Development",
    description:
      "Developing custom blockchain solutions for businesses with specific needs. These solutions can be designed to meet specific business requirements of customers.",
  },
];
const Serves = () => {
  const [currentNumber, setCurrentNumber] = useState(1);
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="serve" id="services">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          Whats We Serve
        </p>
        <Row className="mt-10 md:mt-[80px] hidden md:flex">
          <Col xs={24} md={12}>
            <ServeList
              currentNumber={currentNumber}
              onClick={setCurrentNumber}
            />
          </Col>
          <Col xs={24} md={12}>
            <div className="md:pl-10 xl:pl-[100px] h-full">
              <ServeItem data={servesData[currentNumber - 1]} />
              <DotList
                amount={5}
                activeNumber={currentNumber - 1}
                onClick={setCurrentNumber}
              />
            </div>
          </Col>
        </Row>
        <div className="mt-10 md:mt-[80px] md:hidden">
          <Slider {...settings}>
            {servesData.map((item, index) => (
              <div className="px-2" key={index}>
                <ServeItem data={item} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Serves;
