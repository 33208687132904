import aboutus from "../../assets/aboutus.svg";
import aboutus2 from "../../assets/aboutus2.svg";
import { Row, Col } from "antd";
import AboutUsItem from "../molecules/AboutUsItem";
import goal from "../../assets/goal.svg";
import vision from "../../assets/vision.svg";
import corevalue from "../../assets/corevalue.svg";
import Slider from "react-slick";

const data = [
  {
    title: "Our Goals",
    description:
      "We focus on the goal of becoming one of the leading companies in blockchain product development and providing optimal solutions for our customers. We create breakthrough blockchain products to meet market demand, help customers enhance their business efficiency, and maximize profits.",
    icon: goal,
  },
  {
    title: "Our Vision",
    description:
      "Our vision is to become a trusted partner for businesses in developing and deploying breakthrough blockchain applications. We hope to help businesses harness the potential of blockchain technology to achieve sustainable development and significant growth.",
    icon: vision,
  },
  {
    title: "Our Core Values",
    description:
      "Our core values are professionalism, quality, and innovation. We are committed to providing high-quality blockchain product development services, fully meeting our customers' requirements, and using the most advanced technologies to ensure the security of our customers' information and bring real value to our customers.",
    icon: corevalue,
  },
];
const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="aboutusBg relative overflow-x-hidden" id="about_us">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px] relative z-[1]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          Who We Are?
        </p>
        <Row gutter={[64, 32]} className="mt-10 md:mt-[80px] hidden md:flex">
          {data.map((item, index) => (
            <Col md={12} lg={8} xs={24} key={index}>
              <AboutUsItem
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            </Col>
          ))}
        </Row>
        <div className="mt-10 md:mt-[80px] md:hidden">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div className="px-2" key={index}>
                <AboutUsItem
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <img src={aboutus} className="absolute left-[-120px] top-0" alt="ttk" />
      <img
        src={aboutus2}
        className="absolute right-[-120px] bottom-0"
        alt="ttk"
      />
    </div>
  );
};

export default AboutUs;
