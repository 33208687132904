import { Row, Col } from "antd";
import intro from "../../assets/intro2.svg";
const Intro = () => {
  const handleClickScroll = (path: string) => {
    const element = document.getElementById(path.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      className="introBg pb-[100px] md:pb-[140px] px-6 pt-[180px] relative"
      id="home"
    >
      <div className="max-w-[1280px] w-full mx-auto">
        <Row gutter={[24, 24]} className="md:mt-9">
          <Col lg={12} xs={24}>
            <p className="text-[44px] leading-[55px] text-white font-bold mb-4">
              Empowering your business with Blockchain solutions
            </p>
            <p className="text-[18px] leading-[27px] text-white mb-2">
              TTK Studio is a blockchain technology service provider
              specializing in advanced and high-quality blockchain solutions for
              businesses.
            </p>
            <p className="text-[18px] leading-[27px] text-white">
              We provide application development, consultation, deployment, and
              management services for blockchain systems.
            </p>
            <div
              onClick={() => handleClickScroll("#contact_us")}
              className="text-[20px] cursor-pointer hover:text-primary mx-auto md:mx-0 font-[800] text-primary w-[180px] h-[56px] flex justify-center items-center border-[2px] border-primary bg-transparent outline-none mt-[48px]"
            >
              Contact us
            </div>
            <Row className="mt-10 md:mt-[120px]">
              <Col xs={24} md={8}>
                <div className="md:border-r border-r-white md:pr-3">
                  <p className="text-[18px] text-white mb-3 font-semibold text-center">
                    Smart Solutions
                  </p>
                  <p className="text-[13px] text-white text-center">
                    Commit to provide the most optimal, secure, and efficient
                    solutions for your business.
                  </p>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="md:border-r border-r-white md:px-3 mt-6 md:mt-0">
                  <p className="text-[18px] text-white mb-3 font-semibold text-center">
                    Keep Confidentiality
                  </p>
                  <p className="text-[13px] text-white text-center">
                    Information safety and security are protected by the most
                    advanced technologies.
                  </p>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="md:pl-3 mt-6 md:mt-0">
                  <p className="text-[18px] text-white mb-3 font-semibold text-center">
                    Trusted Companion
                  </p>
                  <p className="text-[13px] text-white text-center">
                    An idealist partner in technology field to bring true value
                    to our clients.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <img
        src={intro}
        className="absolute top-[180px] lg:block hidden right-0 opacity-80"
        alt="ttk"
      />
    </div>
  );
};
export default Intro;
