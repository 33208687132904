import { useNavigate } from "react-router";
import logo from "../../assets/logo.svg";

export const Logo = () => {
  const navigate = useNavigate();
  const handleClickScroll = (path: string) => {
    const element = document.getElementById(path.substring(1));
    if (element) {
      navigate(path);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      className="flex justify-center items-center w-[72px] h-[72px] relative cursor-pointer"
      onClick={() => handleClickScroll("#home")}
    >
      <div className="absolute w-full h-full top-0 left-0 bg-logo blur-[8.5px]"></div>
      <img className="w-[65px] h-[65px] z-[1]" src={logo} alt="logo" />
    </div>
  );
};
