import { Col } from "antd";

type Props = {
  data: {
    name: string;
    image: string;
    url: string;
    descriptions: string[];
  };
};
const PropjectItem: React.FC<Props> = ({ data }) => {
  return (
    <Col xs={24} sm={12} md={8}>
      <div>
        <div className="mx-auto w-[200px] p-5 h-[200px] bg-white flex justify-center items-center">
          <img src={data.image} alt={data.name} className="w-full" />
        </div>
        <p className="text-white text-[28px] text-center mt-6 md:mt-[80px] font-bold">
          {data.name}
        </p>
        <a
          rel="noreferrer"
          href={data.url}
          target="_blank"
          className="text-white text-[18px] text-center mt-4 w-full block"
        >
          {data.url}
        </a>
        {data.descriptions.map((d, index) => (
          <p key={index} className="mt-4 text-white text-[18px] text-center">
            {d}
          </p>
        ))}
      </div>
    </Col>
  );
};

export default PropjectItem;
