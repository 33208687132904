export const LinkedinIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_103_47)">
        <path
          d="M16.0002 0.00390625C24.8334 0.00390625 31.9946 7.16516 31.9946 15.9983C31.9946 24.8314 24.8334 31.9927 16.0002 31.9927C7.16711 31.9927 0.00585938 24.8314 0.00585938 15.9983C0.00585938 7.16516 7.16711 0.00390625 16.0002 0.00390625ZM17.1859 18.1745V23.6189H13.5765V13.212H17.1859V13.8764C17.8984 13.2383 18.7734 12.917 19.7302 12.917C22.2965 12.917 23.6171 14.6758 23.6171 17.1283V23.617L19.9002 23.6177V18.1745C19.9002 17.287 19.7046 16.3745 18.6402 16.3745C17.5302 16.3745 17.1859 17.182 17.1859 18.1745ZM10.5221 12.6845C9.33648 12.6845 8.38336 11.712 8.38336 10.5308C8.38336 9.34953 9.33648 8.37766 10.5221 8.37766C11.7071 8.37766 12.6609 9.34953 12.6609 10.5308C12.6609 11.7114 11.7071 12.6845 10.5221 12.6845ZM8.67086 23.6189V13.212H12.4015V23.6189H8.67086ZM16.0002 0.742656C7.57461 0.742656 0.743984 7.57266 0.743984 15.9983C0.743984 24.4239 7.57461 31.2539 16.0002 31.2539C24.4259 31.2539 31.2565 24.4239 31.2565 15.9983C31.2565 7.57266 24.4259 0.742656 16.0002 0.742656Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_47">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
