type Props = {
  title: string;
  description: string;
  icon: string;
};

const AboutUsItem: React.FC<Props> = ({ title, description, icon }) => {
  return (
    <div className="rounded-[20px] aboutusItem min-h-[468px] md:min-h-[unset] pt-6 pb-9 px-6 backdrop-blur-[5px] h-full">
      <img
        src={icon}
        className="w-[120px] h-[120px] mx-auto rounded-full"
        alt="about-us"
      />
      <p className="mt-[48px] text-[24px] font-semibold text-white text-center">
        {title}
      </p>
      <p className="mt-[48px] text-[13px] text-white text-center">
        {description}
      </p>
    </div>
  );
};
export default AboutUsItem;
