export const FacebookIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_103_44)">
        <path
          d="M16 0C24.8363 0 32 7.16375 32 16C32 24.8363 24.8363 32 16 32C7.16375 32 0 24.8363 0 16C0 7.16375 7.16375 0 16 0ZM13.6069 23.5919V16.3081H12.5781C12.2656 16.3081 12.0125 16.0544 12.0125 15.7425V13.4363C12.0125 13.1256 12.2669 12.8713 12.5781 12.8713H13.6069V11.0169C13.6069 9.26375 15.0288 7.8425 16.7819 7.8425H19.4219C19.7344 7.8425 19.9875 8.09562 19.9875 8.40812V10.7138C19.9875 11.0263 19.7344 11.2794 19.4219 11.2794H17.8594C17.6812 11.2794 17.5344 11.4263 17.5344 11.6044V12.8725L19.3875 12.8713C19.7 12.8931 19.9531 13.1038 19.9531 13.4363C19.8981 14.2175 19.8219 15.0069 19.7556 15.7894C19.7313 16.0813 19.4856 16.3081 19.1925 16.3081H17.5344V23.5919C17.5344 23.9025 17.2806 24.1569 16.9694 24.1569L14.1725 24.1575C13.8606 24.1575 13.6069 23.9038 13.6069 23.5919ZM16 0.738125C7.57125 0.738125 0.738125 7.57125 0.738125 16C0.738125 24.4288 7.57125 31.2619 16 31.2619C24.4288 31.2619 31.2619 24.4288 31.2619 16C31.2619 7.57125 24.4288 0.738125 16 0.738125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_44">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
