import { Row, Col } from "antd";
import dev1 from "../../assets/dev1.png";
import dev2 from "../../assets/dev2.png";
import dev3 from "../../assets/dev3.png";
import dev4 from "../../assets/dev4.png";
import dev5 from "../../assets/dev5.png";
import dev6 from "../../assets/dev6.png";
import dev7 from "../../assets/dev7.png";
import Slider from "react-slick";

const teamData = [
  {
    name: "John Bui",
    image: dev1,
    position: "CEO",
  },
  {
    name: "Naku",
    image: dev2,
    position: "COO & Project Manager",
  },
  {
    name: "Simom",
    image: dev3,
    position: "CTO & Blockchain Developer",
  },
  {
    name: "Quade Lee",
    image: dev4,
    position: "Senior Backend & Solution Architecture",
  },
  {
    name: "Bom",
    image: dev5,
    position: "Blockchain Developer",
  },
  {
    name: "Jeremy",
    image: dev6,
    position: "Back-end Leader",
  },
  {
    name: "Pine",
    image: dev7,
    position: "Front-end Developer",
  },
];
const OurTeam = () => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="ourTeam" id="our_teams">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          Our team
        </p>
        <Row gutter={[24, 24]} className="mt-10 md:mt-[80px] hidden md:flex">
          {teamData.map((member, index) => (
            <Col span={6} key={index}>
              <div className="flex flex-col items-center">
                <img
                  src={member.image}
                  alt={member.name}
                  className="rounded-full w-[160px] h-[160px]"
                />
                <p className="font-bold text-[20px] text-white mt-6">
                  {member.name}
                </p>
                <p className="text-white mt-1 text-[12px]">{member.position}</p>
              </div>
            </Col>
          ))}
        </Row>
        <div className="mt-10 md:mt-[80px] md:hidden">
          <Slider {...settings}>
            {teamData.map((member, index) => (
              <div className="px-2" key={index}>
                <div className="flex flex-col items-center">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="rounded-full w-[160px] h-[160px]"
                  />
                  <p className="font-bold text-[20px] text-white mt-6">
                    {member.name}
                  </p>
                  <p className="text-white mt-1 text-[12px]">
                    {member.position}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
