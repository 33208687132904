import Slider from "react-slick";
import mata from "../../assets/mata.png";
import clubone from "../../assets/clubone.svg";
import ecotrade from "../../assets/ecotrade.svg";
// import vgaming from "../../assets/ecotrade.svg";
import { useRef } from "react";
import { NextIcon } from "../atoms/NextIcon";
import { BackIcon } from "../atoms/BackIcon";
const partnersData = [
  { image: mata, name: "Mata", url: "https://mata.vn" },
  { image: clubone, name: "Clubone", url: "https:/clubone.me" },
  { image: ecotrade, name: "Ecotrade", url: "https://ecotrade.vn" },
  // { image: vgaming, name: "VGaming", url: "https://vgaming.vn" },
];
const OurPartner = () => {
  const ref = useRef<any>(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="our_partner" id="partners">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          Our Partners
        </p>
        <div className="mt-10 md:mt-[80px] relative">
          {partnersData.length > 3 && (
            <div
              className="absolute hidden md:block top-[50%] translate-y-[-50%] left-0 cursor-pointer z-[6]"
              onClick={() => ref.current?.slickPrev()}
            >
              <BackIcon />
            </div>
          )}
          <Slider ref={ref} {...settings}>
            {partnersData.map((item, index) => (
              <div className="px-[30px]" key={index}>
                <div>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-white w-[200px] h-[200px] mx-auto rounded-[24px] flex items-center justify-center"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-[160px] h-[160px] object-contain"
                    />
                  </a>
                  <p className="text-white text-center font-bold text-[20px] mt-6">
                    {item.name}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
          {partnersData.length > 3 && (
            <div
              className="absolute hidden md:block  top-[50%] translate-y-[-50%] right-0 cursor-pointer z-[6]"
              onClick={() => ref.current?.slickNext()}
            >
              <NextIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OurPartner;
