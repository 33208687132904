type Props = {
  data: {
    title: string;
    description: string;
    icon: string;
  };
};

const ServeItem: React.FC<Props> = ({ data }) => {
  return (
    <div className="rounded-[20px] min-h-[522px] md:min-h-[464px] aboutusItem pt-[48px] pb-[42px] px-4 md:px-[50px] backdrop-blur-[5px]">
      <img src={data.icon} className="w-[152px] mx-auto" alt="about-us" />
      <p className="mt-[48px] text-[28px] leading-[35px] font-semibold text-white text-center">
        {data.title}
      </p>
      <p className="mt-[16px] text-[16px] text-white text-center">
        {data.description}
      </p>
    </div>
  );
};

export default ServeItem;
