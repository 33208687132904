import { Row } from "antd";
import nftHunt from "../../assets/nft-hunt.svg";
import nftFairlaunch from "../../assets/nft-fairlaunch.svg";
import ecotrade from "../../assets/ecotrade.svg";
import PropjectItem from "../molecules/PropjectItem";

const projectsData = [
  {
    name: "NFT Snipper",
    image: nftHunt,
    url: "https://nfthunt.app",
    descriptions: [
      "NFT Snipper is a platform for searching and tracking non-fungible tokens (NFTs) on the Ethereum blockchain. NFTs are unique with unparalleled value and are used to represent digital assets, including images, videos, music, and electronic games.",
      "NFT Snipper allows users to search for NFTs by keyword, Ethereum wallet address, or owner name. It also provides detailed information about NFTs, including descriptions, images, and prices. Users can store and manage their favorite NFT lists to track prices and auction times.",
      "Additionally, NFT Snipper provides market analysis and price comparison functions. Users can monitor market trends and make smart buying and selling decisions based on this information. NFT Snipper is a useful tool for those interested in the NFT market and looking for a way to search and track NFTs on the Ethereum blockchain.",
    ],
  },
  {
    name: "NFT Fair Launch",
    image: nftFairlaunch,
    url: "https://nft-fairlaunch.ttkstudio.com",
    descriptions: [
      "NFT Fair Launch - that place helps product building NFT on blockchain to call capital, fundraising by Fair Launch concept. With this product you will meet more angel investors in the blockchain world.",
    ],
  },
  {
    name: "Ecotrade",
    image: ecotrade,
    url: "https://ecotrade.vn/landing",
    descriptions: [
      "Ecotrade is a leading platform in the Social Ecommerce trend, providing convenient sales solutions and leading the market in the industry. With strengths of over 1000 KOCs (Key Opinion Consumers) in more than 20 industries, as well as a professional and modern Studio livestream platform, Ecotrade meets the needs of various industries.",
    ],
  },
];
const Projects = () => {
  return (
    <div className="projects" id="projects">
      <div className="max-w-[1280px] w-full mx-auto px-6 py-[100px]">
        <p className="text-[32px] md:text-[48px] md:leading-[60px] text-white font-bold text-center">
          Our Products/Projects
        </p>
        <Row gutter={[48, 24]} className="mt-10 md:mt-[80px]">
          {projectsData.map((project, index) => (
            <PropjectItem data={project} key={index} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Projects;
