import { Row, Col } from "antd";
import { Logo } from "../atoms/Logo";
import { AddressIcon } from "../atoms/AddressIcon";
import { PhoneIcon } from "../atoms/PhoneIcon";
import { MailIcon } from "../atoms/MailIcon";
import { FacebookIcon } from "../atoms/FacebookIcon";
import { LinkedinIcon } from "../atoms/LinkedinIcon";
import { InternetIcon } from "../atoms/InternetIcon";

const Footer = () => {
  return (
    <div className="footer-custom" id="contact_us">
      <div className="max-w-[1280px] w-full mx-auto px-6 pt-6 md:pt-[100px] pb-4">
        <Row gutter={[24, 24]}>
          <Col md={8} xs={24}>
            <Logo />
            <p className="text-white mt-[18px]">
              Empowering Your Business with Blockchain Solutions.
            </p>
          </Col>
          <Col md={8} xs={24}>
            <p className="font-bold text-[24px] text-white">Contact us at</p>
            <div className="flex items-center mt-4">
              <AddressIcon />
              <p className="text-white flex-1 ml-2">
                14 floor, Anland Complex Building, To Huu Street, La Khe Ward,
                Ha Dong District, Hanoi, Vietnam.
              </p>
            </div>
            <div className="flex items-center mt-4">
              <PhoneIcon />
              <p className="text-white flex-1 ml-2">
                <a className="text-white" href="tel:+84382824306">
                  +84 38 2824 306
                </a>
              </p>
            </div>
            <div className="flex items-center mt-4">
              <MailIcon />
              <p className="text-white flex-1 ml-2">
                <a className="text-white" href="mailto:johnlahboo@gmail.com">
                  johnlahboo@gmail.com
                </a>
              </p>
            </div>
            <p className="text-white mt-4">Or contact us at</p>
            <div className="mt-4 flex items-center">
              <a className="pr-3" href="/">
                <FacebookIcon />
              </a>
              <a className="px-3" href="/">
                <LinkedinIcon />
              </a>
              <a className="pl-3" href="/">
                <InternetIcon />
              </a>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <p className="font-bold text-[24px] text-white">
              Other information
            </p>

            <p className="text-white mt-4">
              <a className="text-white hover:text-primary" href="/">
                Privacy policy
              </a>
            </p>
            <p className="text-white mt-4">
              <a className="text-white hover:text-primary" href="/">
                Frequent questions & answers
              </a>
            </p>
            <p className="text-white mt-4">
              <a className="text-white hover:text-primary" href="/">
                Other policies
              </a>
            </p>
            <p className="text-white mt-4">
              <a className="text-white hover:text-primary" href="/">
                Copyright
              </a>
            </p>
          </Col>
        </Row>
        <p className="mt-[100px] text-white text-center">
          Copyright © TTK Studio
        </p>
      </div>
    </div>
  );
};

export default Footer;
