import { useState } from "react";
import { Logo } from "../atoms/Logo";
import Navbar, { navList } from "../molecules/Navbar";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
const Header = () => {
  const [open, setOpen] = useState(false);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const isActive = (path: string) => {
    return hash === path || (!hash && path === "#home");
  };
  const handleClickScroll = (path: string) => {
    const element = document.getElementById(path.substring(1));
    if (element) {
      navigate(path);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="w-full h-[100px] fixed z-10 top-0 px-6 left-0 flex justify-center items-center bg-headerBg shadow-header backdrop-blur-sm">
      <div className="w-full max-w-[1280px] mx-auto flex items-center justify-between">
        <Logo />
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="lg:hidden" onClick={showDrawer}>
          <MenuOutlined style={{ color: "white", fontSize: "32px" }} />
        </div>
      </div>
      <Drawer
        width={300}
        className="menu_drawer"
        placement="right"
        onClose={onClose}
        visible={open}
      >
        {navList.map((item) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              handleClickScroll(item.path);
              onClose();
            }}
            key={item.name}
            className={`flex items-center justify-start pl-4 py-2 text-[16px] font-bold ${
              isActive(item.path)
                ? "text-primary border-l-[3px] boder-primary"
                : "text-white"
            }`}
          >
            {item.name}
          </div>
        ))}
      </Drawer>
    </div>
  );
};
export default Header;
