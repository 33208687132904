export const BackIcon = () => {
  return (
    <svg
      width="15"
      height="27"
      viewBox="0 0 15 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 25.5L2 13.5L14 1.5" stroke="white" stroke-width="1.5" />
    </svg>
  );
};
